import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKeyStrokes',
})
export class FilterByKeyStrokesPipe implements PipeTransform {
  transform(
    options: {
      title: string;
      value: any;
    }[],
    filter: string,
  ): unknown {
    if (filter) {
      return options.filter((op) =>
        op.title.toLowerCase().includes(filter.toLowerCase()),
      );
    }

    return options;
  }
}
