import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialMediaLinksComponent } from './social-media-links.component';

@NgModule({
  declarations: [SocialMediaLinksComponent],
  imports: [CommonModule],
  exports: [SocialMediaLinksComponent],
})
export class SocialMediaLinksModule {}
