import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-social-media-links',
  templateUrl: './social-media-links.component.html',
  styleUrls: ['./social-media-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SocialMediaLinksComponent implements OnInit {
  @Input() public socialLinks: Navigation['social'];
  @Input() private color: 'default' | 'white' = 'default';

  public iconExtension: string = '.svg';

  ngOnInit(): void {
    if (this.color === 'white') {
      this.iconExtension = '-white.svg';
    }
  }

  constructor() {}
}
