<form [formGroup]="form" (ngSubmit)="submit()">
  <ng-container *ngFor="let field of fields">
    <ng-container
      *ngTemplateOutlet="
        fieldsTemplate;
        context: { field: field, controls: form.controls }
      "
    ></ng-container>
  </ng-container>
</form>

<ng-template #fieldsTemplate let-field="field" let-controls="controls">
  <div
    class="input-container"
    [ngSwitch]="field.type"
    [class]="field.className"
  >
    <app-input
      *ngSwitchCase="'text'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-input>
    <app-input
      *ngSwitchCase="'number'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-input>
    <app-input
      *ngSwitchCase="'email'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-input>
    <app-input
      *ngSwitchCase="'tel'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-input>
    <app-textarea
      *ngSwitchCase="'textarea'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-textarea>
    <app-radio-button
      *ngSwitchCase="'radio'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-radio-button>
    <app-checkbox
      *ngSwitchCase="'checkbox'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-checkbox>
    <app-dropdown
      *ngSwitchCase="'dropdown'"
      [field]="field"
      [control]="controls[field.key]"
    ></app-dropdown>
    <div [class]="field.className || 'group'" *ngSwitchCase="'group'">
      <ng-container *ngFor="let subField of field.fields">
        <ng-container
          *ngTemplateOutlet="
            fieldsTemplate;
            context: { field: subField, controls: controls[field.key].controls }
          "
        ></ng-container>
      </ng-container>
    </div>

    <button
      class="submit"
      type="submit"
      *ngSwitchCase="'submit'"
      [disabled]="form.invalid || form.disabled"
    >
      {{ field.label || 'Submit' }}
    </button>

    <a
      class="submit"
      [ngClass]="{ disabled: form.invalid || form.disabled }"
      *ngSwitchCase="'asyncLink'"
      [href]="href"
      target="_blank"
    >
      {{ field.label || 'View' }}
    </a>

    <div *ngSwitchDefault>
      {{ field.type }}
    </div>
  </div>
</ng-template>
