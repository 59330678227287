import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { StoreService } from '../../../old/app/services/store/store.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FunctionsService } from '../../../old/app/services/functions/functions.service';
import { AnalyticsService } from '../../../old/app/services/analytics/analytics.service';
import { ProfileService } from '../../../old/app/services/profile/profile.service';
import { auth } from 'firebase/app';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // tslint:disable-next-line: variable-name
  private _authError = new BehaviorSubject<firebase.auth.Error>(null);
  // tslint:disable-next-line: variable-name
  private _authSsoError = new BehaviorSubject<firebase.auth.Error>(null);

  constructor(
    private afAuth: AngularFireAuth,
    private fnService: FunctionsService,
    private analyticsService: AnalyticsService,
    private profileService: ProfileService,
    private store: StoreService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public authError(): Observable<firebase.auth.Error> {
    return this._authError;
  }

  public authSsoError(): Observable<firebase.auth.Error> {
    return this._authSsoError;
  }

  public resetAuthError(): void {
    this._authError.next(null);
  }

  public resetAuthSsoError(): void {
    this._authSsoError.next(null);
  }

  public currentUser(): Observable<firebase.User> {
    return this.afAuth.authState;
  }

  public hasPassword(): Observable<boolean> {
    return this.currentUser().pipe(
      map(
        (user) =>
          !!user &&
          user.providerData.some(
            (provider) => !!provider && provider.providerId === 'password',
          ),
      ),
    );
  }

  public isLoggedIn(): Observable<boolean> {
    return this.afAuth.authState.pipe(map((user) => !!user));
  }

  private afterSignIn(
    cred: firebase.auth.UserCredential,
  ): firebase.auth.UserCredential {
    if (cred && cred.user) {
      this._authError.next(null);
      this.savePreSignInData();
      this.profileService.updateTestResultFromStore();
      if (this.route.snapshot.url.toString().includes('checkout')) {
        this.router.navigate([
          this.route.snapshot.queryParams.redirectUrl || '/app/checkout/pay',
        ]);
      }
      if (cred.additionalUserInfo && cred.additionalUserInfo.isNewUser) {
        this.router.navigate([
          this.route.snapshot.queryParams.redirectUrl ||
            '/app/complete-profile',
        ]);
        this.analyticsService.newUserCreated(
          cred.additionalUserInfo.providerId,
        );
        this.completeInvitation();
      } else if (cred.additionalUserInfo) {
        this.router.navigate([
          this.route.snapshot.queryParams.redirectUrl || '/app/profile',
        ]);
        this.analyticsService.signIn(cred.additionalUserInfo.providerId);
      }
      return cred;
    }
  }

  private async completeInvitation(): Promise<void> {
    const invite = this.store.invite.value;
    if (invite) {
      await this.fnService.createInvitation(invite);
    }
  }

  private async savePreSignInData(): Promise<void> {
    const data = this.store.preSignInData.value;
    if (data !== undefined) {
      await this.profileService.updateUserDocument(data);
      this.store.preSignInData.next(undefined);
    }
  }

  public checkActionCode(
    actionCode: string,
  ): Promise<firebase.auth.ActionCodeInfo> {
    return this.afAuth.auth.checkActionCode(actionCode);
  }
  public applyActionCode(actionCode: string): Promise<void> {
    return this.afAuth.auth.applyActionCode(actionCode);
  }

  public verifyPasswordResetCode(actionCode: string): Promise<string> {
    return this.afAuth.auth.verifyPasswordResetCode(actionCode);
  }

  public confirmPasswordReset(
    actionCode: string,
    newPassword: string,
  ): Promise<void> {
    return this.afAuth.auth.confirmPasswordReset(actionCode, newPassword);
  }

  public resetPassword(data: { email: string }, lang: string): Promise<void> {
    this.afAuth.auth.languageCode = lang.substr(0, 2);
    return this.afAuth.auth.sendPasswordResetEmail(data.email);
  }

  public async updatePassword(data: { password: string }): Promise<void> {
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    if (!user) {
      throw new Error('User not logged in!');
    }
    return user.updatePassword(data.password);
  }

  public signOut(): Promise<void> {
    this.analyticsService.signOut();
    return this.afAuth.auth.signOut();
  }

  public async createUserWithEmailAndPassword(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    newsletter?: boolean,
  ): Promise<firebase.auth.UserCredential> {
    const cred: auth.UserCredential = await this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        this._authError.next(error);
        return null;
      });
    if (cred.additionalUserInfo.isNewUser) {
      await this.profileService.updateUserDocument({
        firstName,
        lastName,
        newsletter,
      });
    }
    return this.afterSignIn(cred || null);
  }

  public async signInWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<firebase.auth.UserCredential> {
    const cred = await this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => this._authError.next(error));
    return this.afterSignIn(cred || null);
  }

  public async signInWithPopup(
    provider: firebase.auth.AuthProvider,
  ): Promise<firebase.auth.UserCredential> {
    const cred = await this.afAuth.auth
      .signInWithPopup(provider)
      .catch((error) => {
        this._authSsoError.next(error);
        console.error('Sign in error: ', { error });
      });
    return this.afterSignIn(cred || null);
  }

  public async handleRedirectResult(): Promise<firebase.auth.UserCredential> {
    const cred = await this.afAuth.auth
      .getRedirectResult()
      .catch((error) => this._authError.next(error));
    return this.afterSignIn(cred || null);
  }

  public signInWithPopupGoogle(): Promise<firebase.auth.UserCredential> {
    const provider = new auth.GoogleAuthProvider();
    return this.signInWithPopup(provider);
  }

  public signInWithPopupFacebook(): Promise<firebase.auth.UserCredential> {
    const provider = new auth.FacebookAuthProvider();
    return this.signInWithPopup(provider);
  }

  public signInWithApple(): Promise<firebase.auth.UserCredential> {
    const provider = new auth.OAuthProvider('apple.com');
    return this.signInWithPopup(provider);
  }
}
