import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAssetUrl',
})
export class GetAssetUrlPipe implements PipeTransform {
  transform(
    asset: Asset,
    query?: string,
    imageType?: string,
    element?: HTMLElement,
    fit?: string,
  ): string {
    if (!asset) {
      return '';
    }

    return getAssetUrl(asset, query, imageType, element, fit);
  }
}

export function getAssetUrl(
  asset: Asset,
  query?: string,
  imageType?: string,
  element?: HTMLElement,
  fit?: string,
): string {
  if (typeof asset === 'string') {
    return asset;
  }
  try {
    const { url, contentType, details } = asset.fields.file;
    if (!contentType.includes('image')) {
      return url;
    }
    if (query) {
      if (query === 'auto') {
        const urlObject = new URL(
          url.startsWith('http') ? url : `https:${url}`,
        );

        const { width, height } = getDimensions(details.image, element);

        if (
          element &&
          element.clientWidth > 10 &&
          element &&
          element.clientHeight > 10
        ) {
        }

        /* width and height */
        // urlObject.searchParams.append('w', width.toString());
        // urlObject.searchParams.append('h', height.toString());

        if (fit !== 'contain') {
          urlObject.searchParams.append('fit', 'fill');
        }

        /* Type */
        const type = imageType ? imageType : getImageType(contentType);
        if (type === 'jpg') {
          urlObject.searchParams.append('fm', 'jpg');
          urlObject.searchParams.append('fl', 'progressive');
        }
        if (type === 'webp') {
          urlObject.searchParams.append('fm', 'webp');
        }

        return urlObject.href;
      }
      return url + '?' + query.replace('?', '');
    }
    return url;
  } catch (error) {
    console.error('Invalid asset:', { asset, error });
    return 'invalid-asset.rip';
  }
}

function getDimensions(
  image: { width: number; height: number },
  element?: HTMLElement,
): { width: number; height: number } {
  if (
    element &&
    element.clientWidth > 20 &&
    element &&
    element.clientHeight > 20
  ) {
    // Get from element
    return {
      width: roundToNextHundred(element.clientWidth),
      height: roundToNextHundred(element.clientHeight),
    };
  }

  const ratio = image.height / image.width;
  if (ratio < 1) {
    const baseHeight =
      (element && element.clientHeight > 20 && element.clientHeight) ||
      window.innerHeight;
    const height =
      baseHeight < image.height ? roundToNextHundred(baseHeight) : image.height;
    const width = Math.floor(height * ratio);
    return {
      width,
      height,
    };
  } else {
    const baseWidth =
      (element && element.clientWidth > 20 && element.clientWidth) ||
      window.innerWidth;
    const width =
      baseWidth < image.width ? roundToNextHundred(baseWidth) : image.width;
    const height = Math.floor(width * ratio);
    return {
      width,
      height,
    };
  }
}

function getImageType(contentType: string): string {
  if (pictureIsPng(contentType)) {
    return 'png';
  }
  if (pictureIsGif(contentType)) {
    return 'gif';
  }
  if (pictureIsSvg(contentType)) {
    return 'svg';
  }
  return 'jpg';
}

function pictureIsPng(contentType: string): boolean {
  if (contentType) {
    return contentType.includes('png');
  } else {
    return false;
  }
}
function pictureIsGif(contentType: string): boolean {
  if (contentType) {
    return contentType.includes('gif');
  } else {
    return false;
  }
}
function pictureIsSvg(contentType: string): boolean {
  if (contentType) {
    return contentType.includes('svg');
  } else {
    return false;
  }
}

function roundToNextHundred(value: number): number {
  return Math.round((value + 100) / 100) * 100;
}
