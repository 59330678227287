<ng-container *ngIf="link && type; else contentTemplate">
  <ng-container [ngSwitch]="type">
    <a
      *ngSwitchCase="'internal'"
      [routerLink]="[href]"
      [fragment]="anchor"
      [queryParams]="query"
      routerLinkActive="active"
      class="internal-link"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
    <a
      *ngSwitchCase="'anchor'"
      [href]="href"
      (click)="scrollToAnchor(href, anchor, $event)"
      class="internal-link"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
    <a
      *ngSwitchCase="'external'"
      class="external-link"
      target="_blank"
      [href]="link.href"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
    <a
      *ngSwitchCase="'contact'"
      class="contact-link"
      target="_blank"
      [href]="link.href"
    >
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
    <a *ngSwitchCase="'action'" class="function-link" (click)="executeAction()">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>

    <div *ngSwitchDefault></div>
  </ng-container>
</ng-container>

<ng-template #contentTemplate>
  <span #children>
    <ng-content></ng-content>
  </span>

  <span *ngIf="children.childNodes.length == 0">
    {{ link.text }}
  </span>
</ng-template>
