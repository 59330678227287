import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { NewsletterModalComponent } from './newsletter-modal.component';
import { NewsletterModalService } from './newsletter-modal.service';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [NewsletterModalComponent],
  exports: [NewsletterModalComponent],
  imports: [CommonModule, DynamicFormModule, NgxMarkdownModule],
  providers: [NewsletterModalService],
})
export class NewsletterModalModule {}
