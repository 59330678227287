import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankStatementsModalComponent } from './bank-statements-modal.component';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { BankStatementsModalService } from './bank-statements-modal.service';

@NgModule({
  declarations: [BankStatementsModalComponent],
  exports: [BankStatementsModalComponent],
  providers: [BankStatementsModalService],
  imports: [CommonModule, DynamicFormModule, NgxMarkdownModule],
})
export class BankStatementsModalModule {}
