<div
  class="form-container"
  [ngStyle]="{ background: 'url(' + background + ')' }"
>
  <button class="close" (click)="overlayRef.dispose()">
    <img src="/assets/icons/x-white.png" alt="Close" />
  </button>
  <ng-container *ngIf="!submitted; else submittedTemplate">
    <section class="form-body" markdown ngPreserveWhitespaces>
      {{ formBlock.body }}
    </section>

    <app-dynamic-form
      [fields]="fields"
      class="in-modal"
      (submitted)="submitted = true"
    ></app-dynamic-form>
  </ng-container>
</div>
<ng-template #submittedTemplate>
  <section
    class="form-body"
    markdown
    ngPreserveWhitespaces
    appDynamicMarkdownLink
  >
    {{ formBlock.afterSubmit || '# Submitted!' }}
  </section>
</ng-template>
