import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { certificatesFormFields } from '../../blocks/form-block/fields/certificates';
import { getAssetUrl } from '../../core/contentful-helpers/get-asset-url.pipe';
import { getFields } from '../../core/contentful-helpers/get-fields.pipe';
import { ContentfulService } from '../../core/contentful.service';
import { PageService } from '../../core/page.service';
import { StateService } from '../../core/state.service';
import { constructFields, Field } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-certificates-modal',
  templateUrl: './certificates-modal.component.html',
  styleUrls: ['./certificates-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CertificatesModalComponent implements OnInit, OnDestroy {
  public environment: PageEnvironment;
  public overlayRef: OverlayRef;
  public fields: Field[];
  public background: string;
  public formBlock: FormBlockProps;

  constructor(
    private pageService: PageService,
    private state: StateService,
    private contentfulService: ContentfulService,
  ) {}

  async ngOnInit(): Promise<void> {
    const page = this.pageService.getPage().value;
    const environment = page
      ? page.environment.fields
      : await this.contentfulService.getDefaultEnvironment();
    this.formBlock = getFields(environment.certificateForm);
    this.fields = constructFields(certificatesFormFields, this.formBlock);
    this.background = getAssetUrl(this.formBlock.backgroundImage);
    this.state.hideNavigation(true);
  }

  ngOnDestroy(): void {
    this.state.hideNavigation(false);
  }
}
