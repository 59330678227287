<div
  class="checkbox-container"
  [ngClass]="{ checked: getChecked() | async, disabled: control.disabled }"
>
  <input
    class="checkbox-native"
    type="checkbox"
    [name]="field.key"
    [checked]="getChecked() | async"
    [disabled]="control.disabled"
    #checkbox
  />
  <div
    role="checkbox"
    class="checkbox-custom"
    [title]="'Toggle ' + field.label"
    (click)="handleChange(checkbox.checked)"
  >
    <div class="checkbox-inner-icon"></div>
  </div>
  <label
    [for]="field.key"
    (click)="handleChange(checkbox.checked)"
    markdown
    ngPreserveWhitespaces
    appCheckBoxLinks
  >
    {{ field.label }}
  </label>
</div>
