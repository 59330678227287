import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const fadeUpAnimation = trigger('fadeUp', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'translateY(20px)',
    }),
  ),
  state(
    '*',
    style({
      opacity: 1,
      transform: 'translateY(0)',
    }),
  ),
  transition('* => *', animate('{{time}}ms {{delay}}ms ease-out'), {
    params: { time: 400, delay: 0 },
  }),
]);

export const fadeUpOnlyAnimation = trigger('fadeUpOnly', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'translateY(20px)',
    }),
  ),
  state(
    '*',
    style({
      opacity: 1,
      transform: 'translateY(0)',
    }),
  ),
  transition('void => *', animate('{{time}}ms {{delay}}ms ease-out'), {
    params: { time: 400, delay: 0 },
  }),
]);

export const fadeInAnimation = trigger('fadeIn', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  state(
    '*',
    style({
      opacity: 1,
    }),
  ),
  transition('void => *', animate('{{time}}ms {{delay}}ms ease-out'), {
    params: { time: 400, delay: 0 },
  }),
]);
