import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { ModalComponent } from './modal/modal.component';
import { DynamicLinkModule } from '../dynamic-link/dynamic-link.module';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { SocialMediaLinksModule } from '../social-media-links/social-media-links.module';

@NgModule({
  declarations: [NavigationComponent, ModalComponent],
  imports: [
    CommonModule,
    DynamicLinkModule,
    RouterModule,
    OverlayModule,
    DynamicLinkModule,
    SocialMediaLinksModule,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
