import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private page = new BehaviorSubject<PageFields>(null);

  constructor() {}

  public setPage(page: PageFields): void {
    this.page.next(page);
  }

  public getPage(): BehaviorSubject<PageFields> {
    return this.page;
  }
}
