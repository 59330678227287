import {
  BlockScrollStrategy,
  Overlay,
  OverlayRef,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { ModalComponent } from './modal/modal.component';
import {
  debounceTime,
  filter,
  first,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import { StateService } from '../../core/state.service';
import { Observable } from 'rxjs';
import { ProfileService } from 'apps/website/old/app/services/profile/profile.service';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input() environment: PageEnvironment;

  public overlayRef: OverlayRef;
  public classNames: Observable<string>;
  public user: Observable<any>;
  public showNotification: Observable<boolean>;

  constructor(
    private overlay: Overlay,
    private ruler: ViewportRuler,
    private state: StateService,
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.classNames = this.state.getNavigationClassNames().pipe(
      debounceTime(100),
      map((classNames) => classNames.join(' ')),
    );
    this.user = this.profileService.user();
    this.showNotification = this.router.events.pipe(
      filter((e) => e instanceof ResolveEnd),
      startWith({ url: location.pathname }),
      map((e: ResolveEnd) => !!this.environment.notification),
      shareReplay(1),
    );
  }

  public toggleMenu(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        scrollStrategy: new BlockScrollStrategy(this.ruler, document),
      });
      const portal = new ComponentPortal(ModalComponent);
      const { instance } = this.overlayRef.attach(portal);
      instance.overlayRef = this.overlayRef;
      instance.environment = this.environment;
      /* Close */
      this.overlayRef
        .detachments()
        .pipe(first())
        .subscribe(() => {
          this.overlayRef = null;
        });
    } else {
      this.overlayRef.dispose();
    }
  }

  public signOut(): void {
    this.authService.signOut();
    this.router.navigate(['/']);
  }
}
