import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { buffer, bufferTime, filter, map } from 'rxjs/operators';
import { fadeUpAnimation } from '../../../core/animations';
import { Field } from '../dynamic-form.component';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [fadeUpAnimation],
})
export class DropdownComponent implements OnInit {
  @Input() field: Field;
  @Input() control: FormControl = new FormControl();
  @Output() choose = new EventEmitter<any>();

  public isOpen = false;
  public selected: string;

  public keyboardFilter: Observable<string>;

  constructor() {}

  ngOnInit(): void {}

  public openDropdown(): void {
    this.keyboardFilter = fromEvent(document, 'keydown').pipe(
      bufferTime(1000),
      filter((evs) => evs.length !== 0),
      map((evs: KeyboardEvent[]) => {
        if (evs[0].key === 'Backspace') {
          return '';
        }
        return evs
          .filter((e) => /\w/g.test(e.key) && e.key.length === 1)
          .reduce((acc, ev) => acc + ev.key, '');
      }),
    );
  }

  public selectOption(value: any, title: string): void {
    this.selected = title;
    this.isOpen = false;
    this.choose.emit(value);
    if (this.control) {
      this.control.setValue(value);
    }
  }
}
