import { Validators } from '@angular/forms';
import { MAILCHIMP_TAGS } from 'apps/website/src/environments/environment.common';
import { FunctionsService } from '../../../../../old/app/services/functions/functions.service';
import { Field } from '../../../features/dynamic-form/dynamic-form.component';

export const getMailchimpTags = (
  individual: boolean,
  business: boolean,
): string[] => {
  if (individual && business) {
    return [MAILCHIMP_TAGS.B2C, MAILCHIMP_TAGS.B2B];
  }

  if (business) {
    return [MAILCHIMP_TAGS.B2B];
  }

  return [MAILCHIMP_TAGS.B2C];
};

export const newsletterFormFields = (fnService: FunctionsService): Field[] => [
  {
    key: 'type',
    type: 'group',
    className: 'group-flex-row center',
    fields: [
      {
        key: 'business',
        label: 'Businesses?',
        type: 'checkbox',
      },
      {
        key: 'individual',
        label: 'Individuals?',
        type: 'checkbox',
      },
    ],
  },
  {
    key: 'firstName',
    placeholder: 'First name',
    type: 'text',
    validators: [Validators.required],
  },
  {
    key: 'lastName',
    placeholder: 'Last name',
    type: 'text',
    validators: [Validators.required],
  },
  {
    key: 'email',
    placeholder: 'Email',
    type: 'email',
    className: 'field-full-width',
    validators: [Validators.required],
  },
  {
    key: 'company',
    placeholder: 'Company',
    type: 'text',
    className: 'field-full-width',
  },
  {
    key: 'approval',
    type: 'group',
    className: 'group-flex-row',
    fields: [
      {
        key: 'understand',
        label: 'I accept privacy policy',
        type: 'checkbox',
        validators: [Validators.requiredTrue],
      },
      {
        key: 'submit',
        label: 'Subscribe',
        type: 'submit',
        onSubmit: async ({ email, firstName, lastName, company, type }) => {
          if (!type.business && !type.individual) {
            return {
              code: 'no-type',
              error: true,
              message:
                'Please choose if you’d like the newsletter for businesses or individuals!',
            };
          }

          try {
            await fnService.addNewsletterSubscription({
              email,
              firstName,
              lastName,
              company,
              locale: 'en',
              tags: getMailchimpTags(type.individual, type.business),
            });

            return { code: 'submitted' };
          } catch (error) {
            return {
              code: 'error',
              error: true,
              message: error?.message || 'Oops, something went wrong',
            };
          }
        },
      },
    ],
  },
];
