import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificatesModalComponent } from './certificates-modal.component';
import { CertificatesModalService } from './certificates-modal.service';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [CertificatesModalComponent],
  exports: [CertificatesModalComponent],
  providers: [CertificatesModalService],
  imports: [CommonModule, DynamicFormModule, NgxMarkdownModule],
})
export class CertificatesModalModule {}
