import {
  BlockScrollStrategy,
  Overlay,
  OverlayRef,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { NewsletterModalComponent } from './newsletter-modal.component';

@Injectable()
export class NewsletterModalService {
  public overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private ruler: ViewportRuler) {}

  open(): void {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        scrollStrategy: new BlockScrollStrategy(this.ruler, document),
      });
      const portal = new ComponentPortal(NewsletterModalComponent);
      const { instance } = this.overlayRef.attach(portal);
      instance.overlayRef = this.overlayRef;
      instance.environment = null;
      /* Close */
      this.overlayRef
        .detachments()
        .pipe(first())
        .subscribe(() => {
          this.overlayRef = null;
        });
    } else {
      this.overlayRef.dispose();
    }
  }
}
