import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { InputComponent } from './input/input.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { MarkdownModule as NgxMarkdownModule } from 'ngx-markdown';
import { DynamicCheckBoxMarkdownLinkDirective } from './checkbox/dynamic-markdown-link.directive-for-checkbox.directive';
import { FilterByKeyStrokesPipe } from './dropdown/filter-by-key-strokes.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
@NgModule({
  declarations: [
    DynamicFormComponent,
    InputComponent,
    DropdownComponent,
    CheckboxComponent,
    RadioButtonComponent,
    TextareaComponent,
    DynamicCheckBoxMarkdownLinkDirective,
    FilterByKeyStrokesPipe,
  ],
  exports: [
    DynamicFormComponent,
    InputComponent,
    DropdownComponent,
    CheckboxComponent,
    RadioButtonComponent,
    TextareaComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OverlayModule,
    NgxMarkdownModule.forChild(),
    MatSnackBarModule,
  ],
})
export class DynamicFormModule {}
