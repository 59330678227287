<div class="social">
  <!-- Twitter -->
  <a
    *ngIf="socialLinks.twitter as twitter"
    class="icon"
    [href]="twitter"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/assets/icons/twitter{{ iconExtension }}" alt="Twitter" />
  </a>
  <!-- Facebook -->
  <a
    *ngIf="socialLinks.facebook as facebook"
    class="icon"
    [href]="facebook"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/assets/icons/facebook{{ iconExtension }}" alt="Facebook" />
  </a>
  <!-- Instagram -->
  <a
    *ngIf="socialLinks.instagram as instagram"
    class="icon"
    [href]="instagram"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/assets/icons/instagram{{ iconExtension }}" alt="Instagram" />
  </a>
  <!-- Youtube -->
  <a
    *ngIf="socialLinks.youtube as youtube"
    class="icon"
    [href]="youtube"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="/assets/icons/youtube{{ iconExtension }}" alt="Youtube" />
  </a>
</div>
