import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DynamicLinkModule } from '../dynamic-link/dynamic-link.module';
import { FooterComponent } from './footer.component';
import { SocialMediaLinksModule } from '../social-media-links/social-media-links.module';

@NgModule({
  declarations: [FooterComponent],
  imports: [
    CommonModule,
    DynamicLinkModule,
    RouterModule,
    SocialMediaLinksModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
