import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCheckBoxLinks]',
})
export class DynamicCheckBoxMarkdownLinkDirective {
  constructor(private element: ElementRef<HTMLElement>) {}

  @HostListener('ready')
  public processAnchors(element?: HTMLElement): void {
    const elementToProcess = element || this.element.nativeElement;
    /* Get all routerLinks generated by factory and change them to dynamic links */
    elementToProcess
      .querySelectorAll('a[dynamicLink]')
      .forEach((dynamicLinkElement) => {
        const newElement: HTMLAnchorElement = document.createElement('a');
        newElement.href = dynamicLinkElement.getAttribute('dynamicLink') || '';
        newElement.innerText = dynamicLinkElement.textContent || '';
        newElement.target = '_blank';
        dynamicLinkElement.replaceWith(newElement);
      });
  }
}
