<div
  class="form-container"
  [ngStyle]="{ background: 'url(' + background + ')' }"
>
  <button class="close" (click)="overlayRef.dispose()">
    <img src="/assets/icons/x-white.png" alt="Close" />
  </button>

  <section class="form-body" markdown ngPreserveWhitespaces>
    {{ formBlock.body }}
  </section>

  <app-dynamic-form [fields]="fields"></app-dynamic-form>
</div>
