import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Field } from '../dynamic-form.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() field: Field;
  @Input() control: FormControl;

  constructor() {}

  ngOnInit(): void {}

  public getChecked(): Observable<boolean> {
    return this.control.valueChanges.pipe(startWith(this.control.value));
  }

  public handleChange(checked: boolean): void {
    if (!this.control.disabled) {
      this.control.setValue(!checked);
      this.control.markAsTouched();
    }
  }
}
