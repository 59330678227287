import { Injectable } from '@angular/core';
import { ContentfulClientApi, createClient } from 'contentful';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { filter, first, map, shareReplay, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { getFields } from './contentful-helpers/get-fields.pipe';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private localeId: string;
  private client: ContentfulClientApi;
  private oldCliend: ContentfulClientApi;

  // tslint:disable-next-line: variable-name
  private _articles = new BehaviorSubject<Article[]>([]);
  private articles: Observable<Article[]>;
  // tslint:disable-next-line: variable-name
  private _projects = new BehaviorSubject<Project[]>([]);
  private projects: Observable<Project[]>;

  constructor() {
    this.init();
  }

  public init(localeId: string = 'en-US'): void {
    this.localeId = localeId;
    this.client = createClient({
      ...environment.contentful,
      resolveLinks: true,
    });
    this.oldCliend = createClient({
      ...environment.oldContentful,
      resolveLinks: true,
    });
  }

  public async getEntries<T>(
    query?: {
      content_type: string;
      [key: string]: string | number;
    },
    useOld?: boolean,
  ): Promise<Entry<T>[]> {
    const client = useOld ? this.oldCliend : this.client;
    const entries = await client
      .getEntries<T>({
        locale: this.localeId,
        include: 10,
        ...query,
      })
      .then((res) => res.items)
      .catch(console.error);

    return entries || [];
  }

  public async getPage(slug: string): Promise<Entry<PageFields>> {
    const [page] = await this.getEntries<PageFields>({
      'fields.slug': slug,
      content_type: 'page',
    });
    return page;
  }

  public async getTemplate(
    relatedTypeId: string,
  ): Promise<Entry<TemplateFields>> {
    const [page] = await this.getEntries<TemplateFields>({
      'fields.relatedTypeId': relatedTypeId,
      content_type: 'template',
    });
    return page;
  }

  public async getContext(
    // tslint:disable-next-line: variable-name
    content_type: string,
    slug: string,
  ): Promise<Entry<any>> {
    const [context] = await this.getEntries<any>({
      content_type,
      'fields.slug': slug,
    });
    if (!context) {
      // Try form the old client
      const [old] = await this.getEntries<any>(
        {
          content_type,
          'fields.slug': slug,
        },
        true,
      );
      console.warn('Found from old space:', slug);
      return old;
    }
    return context;
  }

  private async fetchEntriesToObservable<T>(
    contentType: string,
    fetchOld?: boolean,
  ): Promise<T[]> {
    /* Fetch current articles */
    const entries: T[] = await this.getEntries({
      content_type: contentType,
    }).then(getFields);

    /* This is to fetch from old contentful */
    if (fetchOld) {
      const newSlugs = entries.map((entry: any) => entry.slug);
      const oldEntries = (await this.getEntries(
        { content_type: contentType },
        true,
      )
        .then(getFields)
        .then((items) =>
          items.filter((item) => !newSlugs.includes(item.slug)),
        )) as T[];

      return [...entries, ...oldEntries];
    } else {
      return entries;
    }
  }

  private initArticles(): Observable<Article[]> {
    /* Get articles */
    this.fetchEntriesToObservable<Article>('article').then((res) => {
      this._articles.next(res);
    });

    /* Sort and share */
    this.articles = this._articles.pipe(
      filter((items) => items.length !== 0),
      map((items) => {
        return items.sort((a, b) => {
          const getTime = (date: string) => new Date(date).getTime();
          return getTime(b.date) - getTime(a.date);
        });
      }),
      shareReplay(1),
    );

    return this.articles;
  }

  public getArticles(): Observable<Article[]> {
    if (!this.articles) {
      return this.initArticles();
    }

    return this.articles;
  }

  private initProjects(): Observable<Project[]> {
    /* Get projects */
    this.fetchEntriesToObservable<Project>('project', true).then((res) => {
      this._projects.next(res);
    });

    /* Share */
    this.projects = this._projects.pipe(shareReplay(1));
    return this.projects;
  }

  public getProjects(): Observable<Project[]> {
    if (!this.projects) {
      return this.initProjects();
    }

    return this.projects;
  }

  public async getDefaultEnvironment(): Promise<PageEnvironment> {
    return this.getEntries({ content_type: 'environment' }).then(
      ([entry]) => getFields(entry) as PageEnvironment,
    );
  }

  public async getAppPage(slug: string): Promise<AppPageFields> {
    return this.getEntries({
      'fields.slug': slug,
      content_type: 'appPage',
    }).then(([entry]) => getFields(entry) as AppPageFields);
  }

  public async getGlobalContent(): Promise<GlobalContent> {
    const fields = await this.oldCliend.getEntry(environment.globalContentId);
    return getFields(fields);
  }

  public async getCalculatorData(id?: string): Promise<CalculatorData> {
    const fields = await this.oldCliend.getEntry<any>(
      id || environment.calculatorDataId,
    );
    const { calculatorData } = await getFields(fields);
    return calculatorData;
  }
}
