import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFields',
})
export class GetFieldsPipe implements PipeTransform {
  transform(value: Entry<any> | Entry<any>[]): any {
    return getFields<any>(value);
  }
}

export function getFields<T>(data: Entry<any> | Entry<any>[]): any {
  if (Array.isArray(data)) {
    return data.map((entry) => buildBlockFromEntry<T>(entry));
  }
  return buildBlockFromEntry<T>(data);
}

function buildBlockFromEntry<T>(entry: Entry<any>): T {
  if (!entry) {
    return null;
  }
  return {
    ...entry.fields,
    contentId: entry.sys.id,
    contentType: entry.fields.contentType || getEntryContentType(entry.sys),
  };
}

function getEntryContentType(sys: EntrySys): string {
  try {
    return sys.contentType.sys.id;
  } catch (error) {
    return 'null';
  }
}
