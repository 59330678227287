import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Params } from '@angular/router';
import { BankStatementsModalService } from '../bank-statements-modal/bank-statements-modal.service';
import { CertificatesModalService } from '../certificates-modal/certificates-modal.service';
import { NewsletterModalService } from '../newsletter-modal/newsletter-modal.service';

@Component({
  selector: 'app-dynamic-link',
  templateUrl: './dynamic-link.component.html',
  styleUrls: ['./dynamic-link.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicLinkComponent implements OnInit {
  @Input() link: DynamicLinkProps;

  public type: DynamicLinkType;
  public href: string;
  public anchor: string;
  public query: Params;

  constructor(
    private newsletterModal: NewsletterModalService,
    private certificatesModal: CertificatesModalService,
    private backStatementModal: BankStatementsModalService,
  ) {}

  ngOnInit(): void {
    if (this.link) {
      this.type = this.getType(this.link);
    }
  }

  private getType({ type, href }: DynamicLinkProps): DynamicLinkType {
    this.href = href;
    if (type) {
      return type;
    }
    if (!href || href.includes('contentful-ext')) {
      return null;
    }
    if (/^\/?action\//.test(href)) {
      return 'action';
    }
    if (
      (href.startsWith('/') && !href.startsWith('//')) ||
      href.startsWith('#')
    ) {
      this.href = href.replace(/#.+|\?.+/g, '');
      if (href.includes('#')) {
        this.anchor = href
          .replace(/\?.+/g, '')
          .match(/#.+/g)[0]
          .replace('#', '');
      }
      if (href.startsWith('#')) {
        this.href = window.location.pathname + href;
        return 'anchor';
      }
      if (href.includes('?')) {
        // Get query
        const query = href.match(/\?.+/g)[0].replace('?', '');
        // Transform string to params
        const params: Params = query.split('&').reduce((acc, queryString) => {
          const [key, value] = queryString.split('=');
          return {
            ...acc,
            [key]: value,
          };
        }, {});
        this.query = params;
      }

      return 'internal';
    }
    if (href.startsWith('mailto') || href.startsWith('tel')) {
      return 'contact';
    }
    return 'external';
  }

  private getActionNameFromHref(): string {
    return this.link.href.replace(/\/?action(s?)\//g, '');
  }

  public executeAction(): void {
    const action = this.link.action || this.getActionNameFromHref();
    if (!action) {
      console.warn('No function name provided!');
      return null;
    }
    switch (action) {
      case 'newsletter':
        this.newsletterModal.open();
        break;

      case 'certificates':
        this.certificatesModal.open();
        break;

      case 'bank-statements':
        this.backStatementModal.open();
        break;

      case 'share':
        const url = 'start-test';
        const text = 'Calculate your carbon footprint';
        const title = 'Compensate';
        break;

      default:
        break;
    }
  }

  public scrollToAnchor(href: string, anchor: string, e: Event): void {
    if (anchor) {
      silentlyNavigate(href, anchor, e);
    }
  }
}

export function silentlyNavigate(
  href: string,
  anchor?: string,
  e?: Event,
): void {
  if (e) {
    e.preventDefault();
  }
  if (anchor) {
    scrollToElement(anchor);
  }
  setTimeout(() => {
    window.history.pushState(null, '', href);
  }, 1);
}

export function scrollToElement(
  id: string,
  behavior: 'smooth' | 'auto' = 'smooth',
): void {
  const element = document.getElementById(id);
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior });
    }, 1);
  }
}

export function share(url: string, text: string, title: string): void {
  const shareData = {
    title,
    text,
    url,
  };

  navigator.share(shareData);
}
