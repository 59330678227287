import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FunctionsService } from '../../../../old/app/services/functions/functions.service';
import { newsletterFormFields } from '../../blocks/form-block/fields/newsletter';
import { getAssetUrl } from '../../core/contentful-helpers/get-asset-url.pipe';
import { getFields } from '../../core/contentful-helpers/get-fields.pipe';
import { ContentfulService } from '../../core/contentful.service';
import { PageService } from '../../core/page.service';
import { StateService } from '../../core/state.service';
import { constructFields, Field } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-newsletter-modal',
  templateUrl: './newsletter-modal.component.html',
  styleUrls: ['./newsletter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsletterModalComponent implements OnInit, OnDestroy {
  public environment: PageEnvironment;
  public overlayRef: OverlayRef;
  public fields: Field[];
  public background: string;
  public formBlock: FormBlockProps;
  public submitted: boolean;

  constructor(
    private pageService: PageService,
    private state: StateService,
    private fnService: FunctionsService,
    private contentfulService: ContentfulService,
  ) {}

  async ngOnInit(): Promise<void> {
    const page = this.pageService.getPage().value;
    const environment = page
      ? page.environment.fields
      : await this.contentfulService.getDefaultEnvironment();
    this.formBlock = getFields(environment.newsletterForm);
    this.fields = constructFields(
      newsletterFormFields(this.fnService),
      this.formBlock.fields,
    );
    this.background = getAssetUrl(
      this.formBlock.backgroundImage,
      'fm=jpg&q=70&fl=progressive',
    );
    this.state.hideNavigation(true);
  }

  ngOnDestroy(): void {
    this.state.hideNavigation(false);
  }
}
