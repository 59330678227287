<header [ngClass]="{ 'has-notification': showNotification | async }">
  <app-dynamic-link
    class="notification-container"
    *ngIf="showNotification | async"
    [link]="environment.notification"
  >
    <span class="notification-text">
      {{ environment.notification.text }}
    </span>
  </app-dynamic-link>

  <div class="navigation-container" [class]="classNames | async">
    <a class="logo-container" [routerLink]="['/']">
      <img
        class="logo"
        src="/assets/images/compensate/compensate-logo-white.svg"
        alt="Compensate logo"
      />
    </a>

    <div class="app-nav-buttons" *ngIf="user">
      <ng-container *ngIf="user | async; else loginTemplate">
        <a class="app-nav-button profile" [routerLink]="'/app/profile'">
          Profile
        </a>
        <a class="app-nav-button profile" [routerLink]="'/app/settings'">
          Settings
        </a>

        <button class="app-nav-button signout" (click)="signOut()">
          Sign out
        </button>
      </ng-container>
      <ng-template #loginTemplate>
        <!-- <a class="app-nav-button settings" [routerLink]="'/app/login'"> Login </a> -->
      </ng-template>
    </div>

    <button
      class="menu hamburger hamburger--slider"
      type="button"
      aria-label="Menu"
      (click)="toggleMenu()"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
</header>
