import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Field } from '../dynamic-form.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() field: Field;
  @Input() control: FormControl;

  constructor() {}

  ngOnInit(): void {}
}
