<div
  class="input-container"
  [ngClass]="{ open: isOpen }"
  (click)="isOpen = !isOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="openDropdown()"
>
  <input
    type="text"
    readonly
    [placeholder]="
      (keyboardFilter | async | uppercase) || field.placeholder || 'Select'
    "
    #selectElement
    [value]="selected || ''"
  />
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <div class="options-container" @fadeUp>
    <div
      class="option"
      *ngFor="
        let option of field.options
          | filterByKeyStrokes: (keyboardFilter | async)
      "
      (click)="selectOption(option.value, option.title)"
    >
      {{ option.title }}
    </div>
  </div>
</ng-template>
