import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const classNameHideNavigation = 'hidden';
const navigationColorClassNames = ['black', 'white'];

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private navigationClassNames = new BehaviorSubject<string[]>([]);

  constructor() {}

  public getNavigationClassNames(): BehaviorSubject<string[]> {
    return this.navigationClassNames;
  }
  public hideNavigation(value: boolean): void {
    const classNames = this.navigationClassNames.value;
    if (value === true && !classNames.includes(classNameHideNavigation)) {
      this.navigationClassNames.next([...classNames, classNameHideNavigation]);
    }
    if (value === false && classNames.includes(classNameHideNavigation)) {
      this.navigationClassNames.next(
        classNames.filter((className) => className !== classNameHideNavigation),
      );
    }
  }

  public setNavigationColor(color: 'black' | 'white' | 'initial'): void {
    const classNames = this.navigationClassNames.value;
    const filtered = classNames.filter(
      (className) => !navigationColorClassNames.includes(className),
    );
    this.navigationClassNames.next([...filtered, color]);
  }
}
