import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Field } from '../dynamic-form.component';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit {
  @Input() field: Field;
  @Input() control: FormControl;

  constructor() {}

  ngOnInit(): void {}
}
