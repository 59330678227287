<button
  class="menu-button hamburger hamburger--slider is-active"
  type="button"
  aria-label="close menu"
  (click)="close()"
>
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>

<nav *ngIf="environment">
  <p class="description">
    {{ environment.navigation.body }}
  </p>

  <app-social-media-links
    [socialLinks]="environment.navigation.social"
  ></app-social-media-links>
  <div class="links links-left">
    <app-dynamic-link
      *ngFor="let link of environment.navigation.links.left"
      [link]="link"
      (click)="close()"
    >
      <span class="item" style="display: flex">
        <ng-container *ngTemplateOutlet="arrowTemplate"> </ng-container>
        {{ link.text }}
      </span>
    </app-dynamic-link>
  </div>
  <div class="links links-right">
    <app-dynamic-link
      *ngFor="let link of environment.navigation.links.right"
      [link]="link"
      (click)="close()"
    >
      <span class="item" style="display: flex">
        <ng-container *ngTemplateOutlet="arrowTemplate"></ng-container>
        {{ link.text }}
      </span>
    </app-dynamic-link>
  </div>
</nav>

<ng-template #arrowTemplate>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2271.66 1177.04"
    style="enable-background: new 0 0 2271.66 1177.04"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        opacity: 0.4;
        fill: #1b1b1b;
      }
      .st1 {
        fill: #ffffff;
      }
      .st2 {
        fill: #1b1b1b;
      }
    </style>
    <circle class="st0" cx="-732.36" cy="610.1" r="575" />
    <polyline class="st1" points="-851,314.65 -444.62,610.1 -851,905.55 " />
    <g>
      <rect x="247.26" y="518.14" class="st2" width="1674.13" height="100" />
    </g>
    <g>
      <polygon
        class="st2"
        points="1564.95,1000.98 1494.24,930.27 1859.55,564.96 1494.24,199.65 1564.95,128.94 2000.97,564.96 	"
      />
    </g>
  </svg>
</ng-template>
