import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Field {
  key: string;
  type:
    | 'text'
    | 'password'
    | 'email'
    | 'tel'
    | 'textarea'
    | 'dropdown'
    | 'checkbox'
    | 'group'
    | 'radio'
    | 'submit'
    | 'asyncLink';
  label?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  validators?: any[];
  options?: {
    title: string;
    value: any;
  }[];
  fields?: Field[];
  hrefKey?: string;
  onSubmit?: (values: AppObject) => Promise<FormResponse>;
}

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: Field[];
  @Output() submitted = new EventEmitter<any>();

  public form: FormGroup;
  public errorMessage: string;

  /** Optional submit handler derived from a field with type 'submit' */
  private submitHandler?: Field['onSubmit'];

  constructor(private fb: FormBuilder, private snackBar: MatSnackBar) {}

  /**
   * Not all dynamic forms have submit handlers, this function loops through form fields
   * (including nested fields) and returns the onSubmit handler that corresponds to a
   * 'submit' field. Returns undefined if no submit handler is found.
   */
  private findSubmitHandler(fields: Field[]): Field['onSubmit'] {
    // Use a for loop to support escaping early if the submit handler is found
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (field.type === 'submit' && typeof field.onSubmit === 'function') {
        return field.onSubmit;
      }

      if (field.fields instanceof Array) {
        const nestedSubmitHandler = this.findSubmitHandler(field.fields);

        if (nestedSubmitHandler) {
          return nestedSubmitHandler;
        }
      }
    }

    return undefined;
  }

  ngOnInit(): void {
    this.submitHandler = this.findSubmitHandler(this.fields);
    this.form = this.constructFormGroup(this.fields);
  }

  private constructFormGroup(fields: Field[]): FormGroup {
    return this.fb.group(
      fields.reduce((acc, field) => {
        if (field.fields) {
          const subGroup = this.constructFormGroup(field.fields);
          return {
            ...acc,
            [field.key]: subGroup,
          };
        }
        return {
          ...acc,
          [field.key]: [field.value, field.validators],
        };
      }, {}),
    );
  }

  public async submit(): Promise<FormResponse> {
    this.form.disable();
    const response = await this.submitHandler(this.form.value);

    if (response.code === 'submitted') {
      this.submitted.emit(response);

      if (response.message) {
        this.snackBar.open(response.message, 'Ok', {
          panelClass: 'snack',
          duration: 10000,
        });
      }
    } else {
      this.form.enable();
    }

    if (response.error) {
      this.snackBar.open(response.message || 'Error: ' + response.code, 'Ok', {
        panelClass: ['snack', 'snack-error'],
        duration: 10000,
      });
      this.errorMessage = response.message;
    }

    return response;
  }

  get href(): string {
    const asyncLinkField = this.fields.find(
      (field) => field.type === 'asyncLink',
    );
    if (!asyncLinkField) {
      return '';
    }
    const { hrefKey } = asyncLinkField;
    const url = this.form.value[hrefKey];
    if (!url) {
      return '';
    }
    const href = url.startsWith('//') ? `https://${url}` : url;
    return href;
  }
}

export function constructFields(fields: Field[], block?: AppObject): Field[] {
  // Form Blocks that specify a type under the "Fields" JSON field will have a nested `fields` property, so check for both fields and fields.fields
  const blockFields = block?.fields?.fields ?? block?.fields ?? null;

  if (!blockFields) {
    return fields;
  }

  return fields.map((field) => {
    const blockField = blockFields[field.key];
    if (blockField) {
      if (field.fields && blockField.fields) {
        return {
          ...field,
          ...blockField,
          fields: constructFields(field.fields, blockField),
        };
      }

      return {
        ...field,
        ...blockField,
      };
    }

    return field;
  });
}
