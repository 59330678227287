import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicLinkComponent } from './dynamic-link.component';
import { RouterModule } from '@angular/router';
import { DynamicMarkdownLinkDirective } from './dynamic-markdown-link.directive';
import { NewsletterModalModule } from '../newsletter-modal/newsletter-modal.module';
import { CertificatesModalModule } from '../certificates-modal/certificates-modal.module';
import { BankStatementsModalModule } from '../bank-statements-modal/bank-statements-modal.module';

@NgModule({
  declarations: [DynamicLinkComponent, DynamicMarkdownLinkDirective],
  imports: [
    CommonModule,
    RouterModule,
    NewsletterModalModule,
    CertificatesModalModule,
    BankStatementsModalModule,
  ],
  exports: [DynamicLinkComponent, DynamicMarkdownLinkDirective],
})
export class DynamicLinkModule {}
