import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  public environment: PageEnvironment;
  public overlayRef: OverlayRef;
  constructor() {}

  ngOnInit(): void {}

  close(): void {
    this.overlayRef.dispose();
  }
}
