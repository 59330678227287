import { Validators } from '@angular/forms';
import { Field } from '../../../features/dynamic-form/dynamic-form.component';

export const bankStatementsFormFields: Field[] = [
  {
    key: 'year',
    type: 'dropdown',
    options: [],
    label: 'Year',
    validators: [Validators.required],
  },
  {
    key: 'submit',
    label: 'View bank statement',
    type: 'asyncLink',
    hrefKey: 'year',
  },
];
